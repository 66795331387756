import {
    computePosition,
    flip,
    shift,
    offset,
    arrow,
    autoUpdate
  } from '@floating-ui/dom';

  // Function to set up a single tooltip
  function setupTooltip(button, tooltip) {
    const arrowElement = tooltip.querySelector('.arrow');

    function updatePosition() {
      computePosition(button, tooltip, {
        placement: 'top',
        middleware: [
          offset(6),
          flip(),
          shift({padding: 5}),
          arrow({element: arrowElement}),
        ],
      }).then(({x, y, placement, middlewareData}) => {
        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        if (arrowElement) {
          const {x: arrowX, y: arrowY} = middlewareData.arrow;
          const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
          }[placement.split('-')[0]];

          Object.assign(arrowElement.style, {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            right: '',
            bottom: '',
            [staticSide]: '-4px',
          });
        }
      });
    }

    function showTooltip() {
      tooltip.style.display = 'block';
      updatePosition();
    }

    function hideTooltip() {
      tooltip.style.display = 'none';
    }

    const cleanup = autoUpdate(button, tooltip, updatePosition);

    button.addEventListener('mouseenter', showTooltip);
    button.addEventListener('focus', showTooltip);
    button.addEventListener('mouseleave', hideTooltip);
    button.addEventListener('blur', hideTooltip);

    return () => {
      cleanup();
      button.removeEventListener('mouseenter', showTooltip);
      button.removeEventListener('focus', showTooltip);
      button.removeEventListener('mouseleave', hideTooltip);
      button.removeEventListener('blur', hideTooltip);
    };
  }

  // Set up all tooltips
  const buttons = document.querySelectorAll('.tooltip-button');
  buttons.forEach(button => {
    const tooltipId = button.getAttribute('aria-describedby');
    const tooltip = document.getElementById(tooltipId);
    if (tooltip) {
      setupTooltip(button, tooltip);
    }
  });
